import { merge } from 'lodash';
import { combineReducers } from 'redux';
import coreReducers from 'core/reducers';

// Org reducers
import homeSignInNag from 'org/modules/HomeSignInNag/reducers';

// Top level org config
import config from 'org/configs/org.config';

// Not necessary for the moment but putting it in for structural reasons
export default combineReducers(merge(coreReducers, {
  orgConfig: () => config,
  homeSignInNag,
}));
