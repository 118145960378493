import React from 'react';
import { getId } from 'core/utils/analytics/sectionInfo';

/* Components */
import ButtonLandingMobileContent from 'org/modules/Button/ButtonLanding/components/ButtonLandingMobileContent';
import ButtonScrollingModalContent
  from 'org/modules/Modal/components/ButtonScrollingModal/components/ButtonScrollingModalContent';
import TransactionsTabDisclaimer from 'org/modules/Profile/TransactionsTabDisclaimer/TransactionsTabDisclaimer';

/* Images */
import hiwShopOnlineImage from 'org/assets/images/hiw/hiw_home_shop_online.png';
import hiwEarnImage from 'org/assets/images/hiw/hiw_home_earn.png';
import hiwExploreImage from 'org/assets/images/hiw/hiw_home_explore.png';
import hiwShopInstoreImage from 'org/assets/images/hiw/hiw_home_shop_instore.png';
import hiwButtonImg from 'org/modules/Hiw/assets/button.png';
import loadingIndicatorGifInstore from 'org/assets/images/instore/loadingIndicatorInstore.gif';
import loadingIndicatorGif from 'org/assets/images/loadingIndicator.gif';
import homeButtonImage from 'org/assets/images/home_button.png';
import favoriteIconSvg from 'org/assets/images/icons/favorite.inline.svg';
import instoreIconImage from 'org/assets/images/instore/instoreIconGray.svg';
import { renderDirectMerchantDescription } from 'org/utils/directMerchantDescription';
// Safari Button Tutorial assets
import clientLogoImage from 'org/assets/images/logo_reverce.svg';
import animatedArrow from 'org/assets/safariButtonInstallTutorial/animatedArrow.svg';
import nonSafariIcon from 'org/assets/safariButtonInstallTutorial/nonSafari/nonSafariIcon.svg';
import setPermissionClickImage from 'org/assets/safariButtonInstallTutorial/permissionSetting/clickText.png';
import activationReminderImage from 'org/assets/safariButtonInstallTutorial/activationReminder/activationReminder.png';
import activationConfirmationVideo from
  'org/assets/safariButtonInstallTutorial/activationReminder/activationConfirmation.mp4';
import couponMagicImage from 'org/assets/safariButtonInstallTutorial/couponFinder/couponMagic.png';
import couponSuccessVideo from 'org/assets/safariButtonInstallTutorial/couponFinder/couponSuccess.mp4';
import priceMagicVideo from 'org/assets/safariButtonInstallTutorial/priceComparison/priceMagic.mp4';
import searchIntegrationVideo from 'org/assets/safariButtonInstallTutorial/searchIntegration/searchIntegration.mp4';
import storeOfferSettingsVideo from 'org/assets/safariButtonInstallTutorial/storeOfferSettings/storeOfferSettings.mp4';

export const topStores = {
  h: {
    useSliderCounterOnMediaQueriesOnly: [],
    viewAllLinkCTAText: 'See all stores',
    useCarouselOnMediaQueriesOnly: ['M', 'L', 'XL', 'XXL'],
    api: {
      logged_out_params: {
        content_group_id: 3384, // United VHP Top Stores 88x31
      },
    },
  },
  nr: {
    secTitle: 'Suggested stores',
    secTitlePill: 'Browse',
    useCarousel: true,
    slick: {
      variableWidth: true,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 530,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  },
};

// content_group_id should be changed for a real one for this set of merchants
export const buyOnlinePickupInstore = {
  defaults: {
    api: {
      params: {
        content_group_id: 7482,
      },
    },
  },
};

export const topEarn = {
  defaults: {
    api: {
      params: {
        content_group_id: 6711,
      },
    },
  },
};

export const featuredFavorites = {
  defaults: {
    api: {
      params: {
        with_offers: 1,
        content_group_id: 6711,
      },
    },
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    useImageView: true,
    cancelText: 'No thanks. I don\'t like earning extra miles',
    earnType: 'miles',
    emailOptions: {
      email_news_optin: 1,
      email_odotw_optin: 1,
      email_solo_optin: 1,
      email_global_optout: 0,
    },
  },
};

export const forYou = {
    defaults: {
      heading: 'Limited-time bonus offers for you',
    },
  };

export const emailOptInSuccessModal = {
  defaults: {
    earnType: 'bonus miles',
  },
};

export const seasonalFeatured = {
  defaults: {
    api: {
      params: {
        content_group_id: 5936,
      },
    },
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: () => ('Find a store or product'),
    shouldUseHybridSearch: true,
  },
};

export const searchModal = {
  defaults: {
    placeholderLanguage: '',
    shouldUseHybridSearch: true,
  },
};

export const hybridSearchForm = {
  defaults: {
    searchButtonClassName: 'mn_unitedIconSearch',
  },
};

export const quickSearch = {
  defaults: {
    directMerchantName: 'MileagePlus Partner',
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Activate award miles directly at store sites with the MileagePlus Shopping<sup>SM</sup> Button</>
      ),
    },
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View all stores',
    },
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const similarStores = {
  defaults: {
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const featuredOffers502x272 = {
  sp: {
    api: {
      params: {
        content_group_id: 91,
      },
    },
  },
};

export const popularOffers = {
  defaults: {
    showMerchantBonusOffers300x250: true,
  },
  sp: {
    api: {
      params: {
        content_group_id: 11041,
      },
    },
  },
  fs: {
    api: {
      params: {
        content_group_id: 11039,
      },
    },
  },
};

export const offerTable = {
  sp: {
    api: {
      params: {
        section_id: getId('all-offers'),
      },
    },
  },
  fs: {
    api: {
      params: {
        content_group_id: 4,
        section_id: getId('free-shipping'),
        skip_ad_suppressions: 1,
      },
    },
  },
};

export const merchantExperience = {
  defaults: {
    api: {
      params: {
        with_instore: 1,
      },
    },
    termsTitle: 'Mileage terms:',
    directMerchantName: 'United MileagePlus Partner',
    directMerchantCTA: 'MileagePlus Partner',
    directMerchantDescription: renderDirectMerchantDescription(),
  },
};

export const storeRelatedMerchants = {
  defaults: {
    currency: 'miles',
  },
  pd: {
    slick: {
      slidesToScroll: 1,
    },
    useAddToFavorite: true,
  },
  me: {
    useAddToFavorite: true,
  },
};

export const sponsoredTextOffers = {
  bc: {
    api: {
      params: {
        section_id: getId('category-featured'),
        content_type_id: 6,
        limit: 12,
      },
    },
  },
};

export const errorMessage = {
  defaults: {
    showErrorCodes: [404],
    errorMessages: {
      custom: {
        // Overrides all error code titles except the one passed as a data attribute via markup
        title: 'Well, this is awkward',
      },
      404: {
        message: 'We can\'t seem to find the page you\'re looking for. <br> Please try your request again later.',
      },
    },
  },
};

export const buttonLanding = {
  defaults: {
    mobileView: ButtonLandingMobileContent,
    viewOptions: {
      rewardsType: 'miles',
    },
    chromeMobileViewOptions: {
      title: 'Activate miles with the push of a button.',
      imgUrl: hiwButtonImg,
      description: 'Add the new MileagePlus Shopping button to your desktop browser.',
      compareSearchResultsType: 'mileage',
    },
  },
};

export const buttonUninstall = {
  defaults: {
    buttonUninstallIframeUrl: 'https://forms.office.com/r/CG28xJk7SC',
  },
};

export const buttonScrollingModal = {
  defaults: {
    components: {
      ButtonScrollingModalContent,
    },
  },
};

export const contactUsQuestions = {
  defaults: {
    questions: [
      {
        link: ({ siteUrl }) => `${siteUrl}/faq____.htm`,
        linkText: () => <>Frequently asked questions</>,
      },
      {
        link: ({ termsAndConditionsURL }) => termsAndConditionsURL,
        linkText: () => <>Terms and conditions of MileagePlus Shopping<sup>SM</sup></>,
      },
      {
        link: () => 'https://www.united.com/ual/en/US/fly/help/faq/my-account.html',
        linkText: () => <>General questions about my MileagePlus<sup>&reg;</sup> account</>,
      },
      {
        link: () => 'http://www.united.com/customerservice',
        linkText: () => <>General questions about United</>,
      },
    ],
  },
};

export const contactUsDashboard = {
  defaults: {
    useBase64CallBackURL: true,
    clientName: () => <>MileagePlus Shopping<sup>SM</sup></>,
    inquiry: {
      clientName: () => <>MileagePlus<sup>&reg;</sup></>,
      accountIdProd: '0013000000DEmkz',
      accountIdDev: '0016300000z8ATd',
      fields: {
        memberId: {
          inputName: 'clientMemberId',
          error: 'MileagePlus number is required',
        },
      },
    },
  },
};

export const extraRewardsDeals = {
  favorites: {
    secTitle: 'Extra miles deals from your favorites',
  },
};

export const instoreLinkLoader = {
  defaults: {
    animationLoader: {
      gif: loadingIndicatorGifInstore,
    },
    iconSuccessClassName: 'mn_unitedIconSuccess',
  },
};

export const instoreOfferModal = {
  defaults: {
    useBase64CallBackURL: true,
  },
};

export const instoreModal = {
  defaults: {
    useBase64CallBackURL: true,
  },
};

export const LoadingSpinner = {
  defaults: {
    animationLoader: {
      gif: loadingIndicatorGif,
    },
    iconSuccessClassName: 'mn_unitedIconSuccess',
  },
};

export const navBanner = {
  defaults: {
    bannerText: {
      button: {
        title: 'Earn miles with the push of a button',
        text: () => <>Get the new MileagePlus<sup>&reg;</sup> Shopping button and never miss out on miles.</>,
      },
      favorites: {
        title: '',
        text: () => <>Select your favorite stores and get email alerts about extra miles.</>,
        ctaTitle: 'Pick favorites',
      },
    },
  },
};

export const letterboxTOF = {
  defaults: {
    labelText: 'Extra',
    expiresPrefix: 'Valid thru ',
  },
};

export const infoBar = {
  defaults: {
    dealOfTheWeek: {
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
    salesInfoBar: {
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
  },
};

export const merchantLogos = {
  defaults: {
    slick: {
      slidesToShow: 8,
        responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    },
  },
  acq: {
    api: {
      params: {
        limit: 9,
      },
    },
    slick: {
      slidesToShow: 5,
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S'],
  },
  h: {
    apiParams: {
      limit: 8,
    },
    slick: {
      responsive: [
        {
          breakpoint: 821,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 415,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S', 'M', 'L'],
  },
};

export const favoritesIcon = {
  defaults: {
    favoriteIconViewBox: '-1 0 42 35',
    FavoriteIconSvg: favoriteIconSvg,
  },
};

export const navSeasonalFlyout = {
  defaults: {
    tileLabel: 'Extra',
  },
};

export const howItWorks = {
  defaults: {
    hiwItems: [
      {
        image: hiwExploreImage,
        title: 'Explore',
        description: ({ storeCount }) => (
          <>Explore over {storeCount}+ stores, exclusive offers and bonus promotions, all in one place.</>
        ),
      },
      {
        image: hiwShopOnlineImage,
        title: 'Shop online',
        description: () => (
          <>Shop at the store site and check out as you normally would.</>
        ),
      },
      {
        image: hiwShopInstoreImage,
        title: 'Shop in-store',
        description: () => (
          <>Link offers to your preferred card. Shop in-store and pay with your linked card.</>
        ),
      },
      {
        image: hiwEarnImage,
        title: 'Earn',
        description: () => (
          <>
            Make a purchase and you'll earn miles for every dollar you spend
            in addition to those you earn with your card.
          </>
        ),
      },
    ],
  },
};

export const personalizedTopOffers = {
  defaults: {
    viewAllLinkText: 'See all offers',
  },
};

export const testimonials = {
  defaults: {
    testimonialsCopy: [
      'The easiest way to earn miles I\'ve ever used.',
      'I love MileagePlus Shopping! It\'s so easy to use and a very good way to accumulate miles.',
      'Probably the single greatest, not widely known, ' +
      'way to earn airline miles from purchases you were going to make anyway!',
    ],
    checkShouldUseCarousel: (userIsIdentified, isMobileMQ) => (isMobileMQ ? false : !userIsIdentified),
    checkShouldShowIconInCarousel: (activeMQ) => (['XS', 'S'].includes(activeMQ)),
    checkShouldShowIconInSlide: (activeMQ) => (['M', 'L', 'XL', 'XXL'].includes(activeMQ)),
    getSectionTitle: (rewardCount, earnType) => (
      <>Join members earning {rewardCount} {earnType} on average annually</>
    ),
    slick: {
      slidesToShow: 1,
      centerMode: true,
      arrows: true,
      dots: true,
      autoplay: true,
      centerPadding: '0px',
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
          },
        },
      ],
    },
  },
};

export const quickLinksTray = {
  defaults: {
    shouldShowFavoritesLink: false,
    shouldShowAllStoresLink: true,
    extraRewardsLanguage: 'Extra miles',
  },
};

export const emailOptInHome = {
  defaults: {
    emailOptionsSubscribe: {
      email_news_optin: 1,
      email_odotw_optin: 1,
      email_solo_optin: 1,
      email_global_optout: 0,
    },
    emailOptionsUnsubscribe: {
      email_news_optin: 0,
      email_odotw_optin: 0,
      email_solo_optin: 0,
      email_global_optout: 1,
    },
  },
};

export const homeButtonBenefits = {
  defaults: {
    buttonHeader: 'Earn miles with the push of a button',
    buttonDescription: 'Add the button browser extension for Chrome and you\'ll get notifications '
    + 'while shopping so you never forget to earn miles. Plus, you can:',
    buttonCopy: [
      'Automatically apply coupons at checkout',
      'Find new stores offering miles per dollar',
      'Get the lowest price with comparison features',
    ],
    buttonImage: homeButtonImage,
  },
};

export const featuredOffersLogo = {
  defaults: {
    getTitle: (storeCount) => (
      <>Earn at {storeCount}+ stores</>
    ),
  },
};

export const sweeps = {
  defaults: {
    getInactiveSweepsH2Text: () => 'MileagePlus Shopping isn\'t running a sweepstakes right now',
  },
};

export const hybridQuickSearch = {
  defaults: {
    instoreIconImage,
    directMerchantName: 'MileagePlus Partner',
  },
};

export const hybridStores = {
  msr: {
    isMultiMerchantResult: true,
    maxMerchants: Infinity,
  },
};

export const favoritesLanding = {
  defaults: {
    favoritedMerchants: {
      iconClassName: 'mn_unitedIconHeart',
    },
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning MileagePlus miles on your shopping.
      While shopping with MileagePlus Shopping, turn off any ad blockers or use a different browser
      to ensure you earn miles as expected.`
    ),
  },
};

export const profile = {
  defaults: {
    profileDetailsConfig: {
      editButtonLink: 'https://www.united.com/myaccount',
      programName: 'MileagePlus',
    },
    emailDetailsConfig: {
      getSubscribedText: () => <>You are currently opted-in to all MileagePlus Shopping<sup>SM</sup> emails.</>,
      getUnsubscribedText: () => <>You are currently opted out from MileagePlus Shopping<sup>SM</sup>.</>,
    },
    accountSnapshotConfig: {
      getUserInfoText: (userName) => (<>MileagePlus member: {userName}</>),
      getRegistrationDateText: (isMobileMQ, formattedCreateDate) => (
        isMobileMQ
        ? <>MileagePlus Shopping<sup>SM</sup> member <br />since {formattedCreateDate}</>
        : <>MileagePlus Shopping<sup>SM</sup> member since <span className="mn_noWrap">{formattedCreateDate}</span></>
      ),
    },
  },
};

export const profileTransactionsTab = {
  defaults: {
    components: {
      TransactionsTabDisclaimer,
    },
    bonusLabel: 'Mileageplus Shopping',
  },
};

export const profileClickHistoryTab = {
  defaults: {
    checkShouldShowFullDisclaimer: (isMobileMQ) => !isMobileMQ,
    directMerchantInfo: {
      directMerchantMessage: 'This merchant does not offer United MileagePlus Shopping miles.',
      directMerchantTooltip: renderDirectMerchantDescription(),
    },
  },
};

export const safariButtonInstallTutorial = {
  defaults: {
    clientLogoImage,
    animatedArrow,
    nonSafariIcon,
    nonSafariHeader: 'This United Airlines MileagePlus Shopping button is for Safari.',
    setPermissionClickImage,
    setPermissionTextLong: 'Setting the permissions correctly allows the Button to check for miles earning '
    + 'opportunities and coupons whenever you visit a participating merchant site.',
    setPermissionTextShort: 'Shop with confidence, knowing you\'ll never miss out on earning miles!',
    installDescriptionTextShort: 'Ready to effortlessly save and earn miles while you shop?',
    installDescriptionTextLong: ' Discover how the MileagePlus Shopping Button maximizes your miles-earning '
    + 'potential and savings with these tools.',
    activationReminderImage,
    activationConfirmationVideo,
    couponMagicImage,
    couponSuccessVideo,
    priceMagicVideo,
    searchIntegrationVideo,
    storeOfferSettingsVideo,
  },
};
